import React from 'react'
import "../css/ProjectCard.css"

export default function ProjectCard(props) {
    return (
        <a href={props.url} target={(props.status!=='done')?'_self':'_blank'} rel="noreferrer" style={{textDecoration:'none'}}>
            <div className={(props.theme)==='dark'?'projectCardMainBorderDark':'projectCardMainBorderLight'}>
                <img src={props.imageUrl} alt={props.name}></img>
                <p id={(props.theme)==='dark'?'projectCardMainBorderNameDark':'projectCardMainBorderNameLight'}>{props.name}</p>
                <p id={(props.theme)==='dark'?'projectStatusDark':'projectStatusLight'} style={{display:(props.status!=='done')?'block':'none'}}>Work in progress</p>
            </div>
        </a>
    )
}
