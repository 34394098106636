import React from 'react'
import { Col, Row } from 'react-bootstrap';
import '../css/Homepage.css';

export default function Homepage(props) {
    return (
        <div className='homeMain' id="home">
            <Row style={{margin:0,padding:0}}>
                <Col lg={6} md={6} sm={12} style={{margin:'0',padding:'0'}}>
                <div>
                        <div className={(props.theme)==='dark'?'introDark':'introLight'}>
                            <h2>Hi, I am Sohail Ali Malik</h2>
                            <h1>Developer. Product Person</h1>
                            <h3>Multidisciplinary developer who hacks, makes, and occasionally breaks things.</h3>
                            <h3>Less moody in real life. ✌️</h3>
                            <br></br>
                            <h4>
                                <a href='#about' className='link'>
                                    Learn More
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"/>
                                        <path fillRule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                </a>
                            </h4>
                        </div>
                        <div className='socialDiv'>
                            <div className='socialItem'>
                                <a href='https://instagram.com/sohail.99__?utm_medium=copy_link' rel='noreferrer' target='_blank'>
                                    <img src='https://firebasestorage.googleapis.com/v0/b/porfolio-1613303051072.appspot.com/o/assets%2Ficons8-instagram.svg?alt=media&token=bc44edcc-f4a1-4751-9aa4-b3c4765143f7' alt='Instagram'></img>
                                </a>
                            </div>
                            <div className='socialItem'>
                                <a href='https://github.com/Sohail099' rel='noreferrer' target='_blank'>
                                    <img src='https://firebasestorage.googleapis.com/v0/b/porfolio-1613303051072.appspot.com/o/assets%2FGithub.svg?alt=media&token=93ac8f6d-3d3a-48cf-9227-69bad14c3cf5' alt='Github'/>
                                </a>
                            </div>
                            <div className='socialItem'>
                                <a href='https://www.linkedin.com/in/sohail-ali-malik' rel='noreferrer' target='_blank'>
                                    <img src='https://firebasestorage.googleapis.com/v0/b/porfolio-1613303051072.appspot.com/o/assets%2Flogos_linkedin-icon.svg?alt=media&token=1307cf27-e314-40a2-b349-97e7dae1595a' alt='LinkedIn'/>
                                </a>
                            </div>
                            <div className='socialItem'>
                                <a href='https://stackoverflow.com/users/10690494/sohail-99' rel='noreferrer' target='_blank'>
                                    <img src='https://firebasestorage.googleapis.com/v0/b/porfolio-1613303051072.appspot.com/o/assets%2Flogos_stackoverflow-icon.svg?alt=media&token=1d54e51c-7400-4c5f-b6d6-f8df111475bc' alt='Stackoverflow'/>
                                </a>
                            </div>
                            <div className='socialItem'>
                                <a href='mailto:sohailalimalik18@gmail.com'>
                                    <img src='https://firebasestorage.googleapis.com/v0/b/porfolio-1613303051072.appspot.com/o/assets%2Flogos_google-gmail.svg?alt=media&token=39dd292a-7e81-4f56-9678-ccff65271da5' alt='Gmail'/>
                                </a>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} style={{margin:"0",padding:"0"}}>
                    <div className="image">
                        <img className='image2' src='https://firebasestorage.googleapis.com/v0/b/porfolio-1613303051072.appspot.com/o/assets%2Fmy-image.webp?alt=media&token=6dbbd639-3a24-4cbc-a5bd-b1e7ad42eb09' alt="Sohail's Picture"></img>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
