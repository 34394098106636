import React, { useState } from "react";
import "../css/Navbar.css";
import { Container, Navbar, Nav, Button } from "react-bootstrap";
import DarkModeToggle from "react-dark-mode-toggle";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export default function MyNavbar(props) {
  const [isDarkMode, setIsDarkMode] = useState(() => false);
  // console.log("Props : ",props);
  //   const navbarStyle = {
  //       justifyContent:'flex-end',
  //       alignItems: "center",
  //       display:'flex',
  //       // 'background-color':'red'
  //   }
  const changeTheme = (event) => {
    console.log("changeTheme called");
    console.log("Event : ", event);
    setIsDarkMode(event);
    if (event) {
      props.changeModeFunction("dark");
    } else {
      props.changeModeFunction("light");
    }
  };
  const downloadResume = async () => {
    console.log("downloadResume() Called");
    const storage = getStorage();
    getDownloadURL(ref(storage, 'resume/Resume___Sohail_Ali_Malik.pdf'))
      .then(async (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `Resume___Sohail_Ali_Malik.pdf`,
        );
        link.setAttribute('target', "_blank");
        link.setAttribute("rel", "noreferrer")
        document.body.appendChild(link);
        
        // Start download
        link.click();
        
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        // Handle any errors
        console.log("Error : ", error)
      });
  }
  return (
    <Navbar collapseOnSelect expand="lg" bg={props.theme} variant={props.theme} style={{ position: 'sticky', top: '0' }}>
      <Container>
        <Navbar.Brand href="#home">Sohail Ali Malik </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav ">
          <Nav className=" d-flex  align-items-center  w-100 justify-content-end">
            <Button variant="primary" onClick={downloadResume}>Resume</Button>
            <Nav.Link href="#home" className="mx-3 font">
              Home
            </Nav.Link>
            <Nav.Link href="#about" className="mx-3 font">
              About
            </Nav.Link>
            <Nav.Link href="#technologies" className="mx-3 font">
              Tech Stack
            </Nav.Link>
            <Nav.Link href="#work" className="mx-3 font">
              Work
            </Nav.Link>
            <Nav.Link href="#contact" className="mx-3 font">
              Contact
            </Nav.Link>
            <DarkModeToggle
              onChange={changeTheme}
              checked={isDarkMode}
              size={50}
            />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}