import React, { useState, useEffect } from 'react';
import "../css/Technologies.css";
import TechItems from "./TechCard";
import { Row,Col } from 'react-bootstrap';
import { collection, getDocs, addDoc, query, orderBy } from "firebase/firestore";
const { db } = require("../helpers/firebase");
// const TechStack = require("../techData.json");

export default function Technologies(props) {
    const [technologies,setTechnologies] = useState([])
    const getTechnologies = async()=>{
        console.log("getTechnologies Called()");
        let technologies = []
        // const technologies = await getDocs(collection(db,"Technologies"))
        // .then((items)=>{
        //     return items.docs
        // })
        const q = query(collection(db, 'Technologies'), orderBy('order'));
        // console.log("q");
        const data = await getDocs(q);
        data.forEach(element => {
            let elemData = element.data();
            technologies.push(elemData)
        });
        setTechnologies(technologies)
    };


    /*const addTechnologies = async(techStackList)=>{
        console.log("addTechnologies() Called");
        console.log("Tech List : ",techStackList);
        for(let i=0;i<techStackList.length;i++){
            try {
                const docRef = await addDoc(collection(db, "Technologies"), {
                    name: techStackList[i].name,
                    imageUrl: techStackList[i].imageUrl,
                    homepage: techStackList[i].homepage,
                    order: i+1,
                });
                console.log("Document written with ID: ", docRef.id);
            } catch (e) {
                console.error("Error adding document: ", e);
            }
        }
    }*/
    useEffect(() => {
        // 👇️ only runs once
        getTechnologies();
        // addTechnologies(TechStack.data);
      }, []);

    return (
        <div className='techmain' id='technologies'>
            <div className={(props.theme)==='dark'?'techstackDark':'techstackLight'}>
                <h1>My technical stack</h1>
            </div>
            <div className='container'>
                <Row className='row justify-content-center'>
                    {technologies.map((element,index=0)=>{
                        // let elementData = element.data();
                        return( <Col lg={3} md={3} sm={4} xs={3} className='d-flex justify-content-center my-3' key={index++}>
                            <TechItems name={element.name} url={element.homepage} imageUrl={element.imageUrl} theme={props.theme}/>
                        </Col>)
                    })}  
                </Row>
            </div>
            <div className={(props.theme)==='dark'?'loadingDark':'loadingLight'}>
                <h3>Gearing up with new Technologies</h3>
            </div>
        </div>
    )
}
