import "../css/TechCard.css";

import React from 'react'
// import { Link } from "react-router-dom";

export default function TechCard(props) {
    // console.log("Technologies Card props : ",props);
    return (
        <a href={props.url} target='_blank' rel="noreferrer" style={{textDecoration:'none'}}>
            <div className={(props.theme)==='dark'?'mainBorderDark':'mainBorderLight'}>
                <img src={props.imageUrl} alt={props.name}></img>
                <p id={(props.theme)==='dark'?'nameDark':'nameLight'}>{props.name}</p>
            </div>
        </a>
    )
}
