// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA4QfpKOswAyK8yP07925j1rvKrQx8VT-0",
  authDomain: "porfolio-1613303051072.firebaseapp.com",
  databaseURL: "https://porfolio-1613303051072-default-rtdb.firebaseio.com",
  projectId: "porfolio-1613303051072",
  storageBucket: "porfolio-1613303051072.appspot.com",
  messagingSenderId: "973750388372",
  appId: "1:973750388372:web:0d918aa81b24177a9bd681",
  measurementId: "G-PXVPTHP152"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const db = getFirestore(app);