import React, { useState, useEffect } from 'react'
import "../css/Projects.css"
import ProjectCard from "./ProjectCard";
// import ProjectItems from "../projects.json"
import { Row,Col } from 'react-bootstrap';
import { collection, getDocs, addDoc, query, orderBy } from "firebase/firestore";
const { db } = require("../helpers/firebase");

export default function Projects(props) {
    const [projects,setProjects] = useState([])
    const getProjects = async()=>{
        console.log("getProjects Called()");
        let projects = []
        const q = query(collection(db, 'Projects'), orderBy('order'));
        const data = await getDocs(q);
        data.forEach(element => {
            let elemData = element.data();
            projects.push(elemData)
        });
        setProjects(projects)
    };

    /*const addProjects = async(projectList)=>{
        console.log("addProjects() Called");
        console.log("Proj List : ",projectList);
        for(let i=0;i<projectList.length;i++){
            try {
                const docRef = await addDoc(collection(db, "Projects"), {
                    name: projectList[i].name,
                    imageUrl: projectList[i].imageUrl,
                    homepage: projectList[i].homepage,
                    status: projectList[i].status,
                    order: i+1,
                });
                console.log("Document written with ID: ", docRef.id);
            } catch (e) {
                console.error("Error adding document: ", e);
            }
        }
    }*/

    useEffect(() => {
        // 👇️ only runs once
        getProjects();
        // addProjects(ProjectItems.data);
    }, []);


    return(
        <div className='projectsMain' id='work'>
            <div className={(props.theme)==='dark'?'projectsDark':'projectsLight'}>
                <h1>My Contributions</h1>
            </div>
            <div className='container'>
                <Row className='row'>
                {projects.map((element,index=0)=>{
                        return( 
                            <Col lg={4} md={3} sm={4} xs={4} className='col-md-4 d-flex justify-content-center my-5' key={'key'+index}>
                                <ProjectCard name={element.name} url={element.homepage} imageUrl={element.imageUrl} status={element.status} theme={props.theme}/>
                            </Col>
                        )
                    })}
                </Row>
            </div>
        </div>
    )
}