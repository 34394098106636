import React from 'react'
import "../css/About.css"
import {Row, Col} from 'react-bootstrap'

export default function About(props) {
    return (
        <div className='aboutMain' id="about">
            <Row style={{margin:0,padding:0}}>
                <Col lg={6} md={6} sm={12} style={{margin:'0',padding:'0'}}>
                    <div className={(props.theme)==='dark'?'aboutDark':'aboutLight'}>
                        <h1>I build value by code.</h1>
                        <p className={(props.theme)==='dark'?'paragraph content-paragraphDark':'paragraph content-paragraphLight'}>I'm Sohail Ali Malik, but you can call me SAM</p>
                        <p className={(props.theme)==='dark'?'paragraph content-paragraphDark':'paragraph content-paragraphLight'}>Originally hailing from the Indore, India. Pursuing Integrated Master of Technology in Information Technology from International Institute of Professional Studies. I’ve been honing my skills in development, production and management roles building products for the web, mobile, and games.</p>
                        <p className={(props.theme)==='dark'?'paragraph content-paragraphDark':'paragraph content-paragraphLight'}>I strive to create elegant solutions that surprise and delight users, while keeping complex technical dependencies in mind for implementation, and scalability.</p>
                        <p className={(props.theme)==='dark'?'paragraph content-paragraphDark':'paragraph content-paragraphLight'}>I’ve worked with small, agile teams on projects and larger development teams with product lifecycles.</p>
                        <p className={(props.theme)==='dark'?'paragraph content-paragraphDark':'paragraph content-paragraphLight'}>Depending on the team, resources and deliverables I’ve worn many hats over the years - holding titles like Back-end Developer, and Database Designer</p>
                        <p className={(props.theme)==='dark'?'paragraph content-paragraphDark':'paragraph content-paragraphLight'}>I also occasionally give talks on design and product development.</p>
                        <p className={(props.theme)==='dark'?'paragraph content-paragraphDark':'paragraph content-paragraphLight'}>
                            Have an interesting project?&nbsp;
                            <a href="#contact" className="link">Let's talk</a>.
                        </p>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} style={{margin:'0',padding:'0'}}>
                    <div className='aboutImageDiv'>
                        <img className='aboutImageDivImgTag' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTADr-3PrecoVO1hoAmq1qUaXmfmPNM30r2Nw&usqp=CAU'></img>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
